import * as z from 'zod'

import { VxConfig } from '../schemas/eileen-config'

/**
 * Typed environment for the static env from `import.meta.env`.
 *
 * If you want to know if you're a prod or a dev build, use `import.meta.env`.
 * Otherwise use this {@link getEnv} than the untyped `import.meta.env` so that
 * your environment is correctly validated against the static type.
 */
const STATIC_ENV_SCHEMA = z.object({
  /** This is the app title */
  VITE_APP_TITLE: z.string().default('Pladia Empower'),

  /** This is the content bundle to use */
  VITE_APP_CONTENT_DOMAIN:
    // This is defaulted in test
    // eslint-disable-next-line pladia-vite/vite-use-import-meta
    process.env.NODE_ENV === 'test'
      ? z.string().default('-fake-')
      : z.string().url(),

  /** AP Standard Deployment Params */
  VITE_CLIENT: z.string().optional(),
  VITE_PROJECT: z.string().optional(),
  VITE_COMPONENT: z.string().optional(),
  VITE_DEPLOY_ENV: z.string().optional(),

  /** Sentry Params */
  VITE_SENTRY_DSN: z.string().url().optional(),
  VITE_SENTRY_RELEASE: z.string().optional(),
  VITE_SENTRY_ENVIRONMENT: z.string().optional(),

  /** Rudderstack Params */
  VITE_RUDDERSTACK_WRITE_KEY: z.string().optional(),
  VITE_RUDDERSTACK_DATA_PLANE_URL: z.string().url().optional(),

  /** The app version (the Pladia version, not the package.json version) */
  VITE_APP_RELEASE_VERSION: z
    .string()
    .default('')
    // Strip tag prefixes from the version
    .transform(value => value.replace(/^(release@|v)/, '')),

  VITE_DELIGHTED_SURVEY_ID: z.string().optional(),
  VITE_DELIGHTED_TRIGGER_TIME: z.coerce.number().default(470),
  VITE_DELIGHTED_TRIGGER_PAGES: z.coerce.number().int().default(10),

  /** This is the package.json version */
  VITE_APP_VERSION: z.string().optional(),

  /** Override languages settings */
  VITE_APP_DEFAULT_LANGUAGE: z.string().default('en'),
  VITE_APP_LANGUAGES: z
    .string()
    .default('en;en-US;de;es;es-MX;fr;hi;it;ja;ko;pt;pt-BR;vi;zh-Hans;zh-Hant')
    .transform(value => value.split(';')),
  /** Override the vx-config setting */
  VITE_APP_VX_CONFIG: z
    .string()
    .optional()
    .transform(value => value || undefined),

  /** Feature flags to override outside the bundle */
  VITE_APP_FEATURE_FLAGS: z
    .string()
    .default('')
    .transform(value => value.split(';'))
    .transform(value => new Set(value)),

  /** Wayfinding Navtrack */
  VITE_WAYFINDING_NAVTRACK: z.string().optional(),
})

/**
 * Typed environment for the static env from `window.$pladia`.
 *
 * Use this {@link getEnv} than the untyped `import.meta.env` so that
 * your environment is correctly validated against the static type.
 */
const DYNAMIC_ENV_SCHEMA = z.object({
  /** The device role (for analytics) */
  deviceRole: z
    .nativeEnum(VxConfig.DeviceRole)
    .default(VxConfig.DeviceRole.BYOD),
  /** Whether or not the app should reset the session when starting up */
  resetSession: z.boolean().default(false),
  /** Whether or not we should disable caching any content */
  disableCache: z.boolean().default(false),
})

class BadEnvironmentError extends Error {
  name = 'Bad Environment Error'
}

/**
 * Returns the build environment, but only the keys we have validated against
 * the Zod schema {@link STATIC_ENV_SCHEMA}.
 */
export default (): z.infer<typeof STATIC_ENV_SCHEMA> &
  z.infer<typeof DYNAMIC_ENV_SCHEMA> => {
  const staticEnv = STATIC_ENV_SCHEMA.safeParse(import.meta.env)
  const dynamicEnv = DYNAMIC_ENV_SCHEMA.safeParse(
    '$pladia' in self ? self.$pladia : {},
  )

  if (staticEnv.success && dynamicEnv.success) {
    return { ...staticEnv.data, ...dynamicEnv.data }
  }

  const message = [
    ...(staticEnv.error?.errors ?? []),
    ...(dynamicEnv.error?.errors ?? []),
  ]
    .map(elem => `${elem.path}: ${elem.message}`)
    .join('\n')

  throw new BadEnvironmentError(message)
}
