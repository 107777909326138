// This service worker can be customized!
// See https://developers.google.com/web/tools/workbox/modules
// for the list of available Workbox modules, or add any other
// code you'd like.
// You can also remove this file if you'd prefer not to use a
// service worker, and the Workbox build step will be skipped.

import { clientsClaim } from 'workbox-core'

import type { ToWorkerEventHandlerMap } from '../types/worker'
import { WorkerEventSchema } from '../types/worker'
import { debugError, debugLog } from '../utils/debug'

import { cacheAssets } from './prefetchAssets'
import { postRegisterRoutes, preRegisterRoutes } from './registerRoute'

// Register event handlers for messages coming from the main process
const EVENTS: ToWorkerEventHandlerMap = {
  SKIP_WAITING: async () => {
    self.skipWaiting()
    clientsClaim()
  },
  INITIALIZE: async event => {
    // Set the dynamic environment from the main context
    Object.assign(self, { $pladia: event.$pladia })

    // Complete initialisation
    postRegisterRoutes()
  },
  CACHE_ASSETS: cacheAssets,
}

// Receives an event from the app, then based on the type of event,
// it will execute the corresponding handler
self.addEventListener('message', event => {
  try {
    const data = WorkerEventSchema.parse(event.data)
    const eventFn = EVENTS[data.type]
    debugLog('Service Worker', `${data.type} event`, data)

    // The cast here is because data is a union of all possible event types
    // and although we've looked up the function for that event type, and must
    // be correct, TS can't see that.
    event.waitUntil(eventFn(data as any))
  } catch (exc) {
    debugError('Service Worker', exc, event)
  }
})

// If we're in the waiting state, skip it
// This is safe to do because we always update
self.skipWaiting()
// Claim all windows for this service worker
clientsClaim()
// Register routes not dependent on the environment
preRegisterRoutes()
