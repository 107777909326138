import * as z from 'zod'

/** This is a workbox event that we're going to skip waiting */
const SkipWaitingSchema = z.object({
  type: z.literal('SKIP_WAITING'),
})

const InitializeSchema = z.object({
  type: z.literal('INITIALIZE'),
  /** This is the `self.$pladia` global block */
  $pladia: z.object({}).passthrough(),
})

/** A request to cache assets */
const CacheAssetEventSchema = z.object({
  type: z.literal('CACHE_ASSETS'),
  /** The ids of all related entities that should be cached */
  cachingIds: z.array(z.string()),
  assets: z.array(
    z.object({
      ref: z.object({
        typename: z.string(),
        id: z.string(),
      }),
      url: z.string(),
      contentType: z.string(),
      language: z.string().optional(),
      copyright: z.string().optional(),
      distributionControl: z.enum(['unrestricted', 'onsite', 'admin-only']),
      externalId: z.string().optional(),
    }),
  ),
})

export const WorkerEventSchema = z.discriminatedUnion('type', [
  SkipWaitingSchema,
  InitializeSchema,
  CacheAssetEventSchema,
])

export type CacheAssetsEvent = z.infer<typeof CacheAssetEventSchema>

export type ToWorkerEvent = z.infer<typeof WorkerEventSchema>

export type WorkerEventMap = {
  [K in ToWorkerEvent['type']]: Extract<ToWorkerEvent, { type: K }>
}

export enum WorkerFetchResultType {
  Success = 'SUCCESS',
  Error = 'ERROR',
  TerminalError = 'TERMINAL_ERROR',
}

export type WorkerEventResponse<TResult = unknown> = {
  status: WorkerFetchResultType
  result?: TResult
  error?: unknown
}

export type ToWorkerEventHandlerMap = {
  [K in keyof WorkerEventMap]: (event: WorkerEventMap[K]) => Promise<void>
}
