/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'
import * as VixenAssets from './vixen-assets'
import * as VixenSpatial from './vixen-spatial'
import * as OcelotContent from './ocelot-content'
import * as OcelotHibiki from './ocelot-hibiki'

export const meta: Meta = {
  name: 'Ocelot Catalog',
  moduleName: 'ocelot-catalog',
  version: '0.14.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- catalog-page ---
export interface CatalogPageSchema
  extends VixenCore.StyledNamedSchema,
    VixenCore.RoutableSchema,
    OcelotContent.SlottedContentSchema {
  'preview-image'?: GazelleRefSchema | null
  'class-name'?: string | null
}

export interface ICatalogPage
  extends VixenCore.IStyledNamed,
    VixenCore.IRoutable,
    OcelotContent.ISlottedContent {
  previewImage?: GazelleRef<VixenAssets.Picture>
  className?: string
}

// --- catalog-common ---
export interface CatalogCommonSchema {
  'external-id'?: string | null
  'catalog-tags': Array<GazelleRefSchema>
  dates: Record<string, string>
}

export interface ICatalogCommon {
  externalId?: string
  catalogTags: Array<GazelleRef<CatalogTag>>
  dates: Record<string, string>
}

// --- catalog-positionable ---
export interface CatalogPositionableSchema
  extends VixenSpatial.PositionableSchema {
  'wall-id'?: string | null
  space?: VixenSpatial.FeatureRefSchema | null
  'vantage-spaces': Array<VixenSpatial.FeatureRefSchema>
  feature?: VixenSpatial.FeatureRefSchema | null
  venue?: VixenSpatial.VenueRefSchema | null
}

export interface ICatalogPositionable extends VixenSpatial.IPositionable {
  wallId?: string
  space?: VixenSpatial.FeatureRef
  vantageSpaces: Array<VixenSpatial.FeatureRef>
  feature?: VixenSpatial.FeatureRef
  venue?: VixenSpatial.VenueRef
}

// === structs ===
// === entities ===
export interface CatalogTagSchema extends CatalogPageSchema {}

export interface ICatalogTag extends ICatalogPage {}

type CatalogTagSchemaType = z.Schema<
  CatalogTag,
  z.ZodTypeDef,
  CatalogTagSchema
>

export const CatalogTagSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      content: z
        .array(OcelotContent.SlottedContent.ContentSlotSchema)
        .default([]),
      name: z.record(z.string()),
      'styled-name': z.record(z.string()),
      'preview-image': GazelleRefSchema.nullish(),
      'class-name': z.string().nullish(),
    }),
  )
  .transform(
    value => new CatalogTag(recase(value)),
  ) satisfies CatalogTagSchemaType

export class CatalogTag
  extends Entity<CatalogTagSchema>
  implements ICatalogTag
{
  static readonly typename = 'catalog-tag'
  static readonly schema: CatalogTagSchemaType = CatalogTagSchema
  static readonly parse = CatalogTagSchema.parse
  static readonly fields = [
    'ref',
    'content',
    'name',
    'styled-name',
    'preview-image',
    'class-name',
  ]

  readonly typename = 'catalog-tag'

  ref!: GazelleRef<CatalogTag>
  content!: Array<OcelotContent.SlottedContent.ContentSlot>
  name!: Record<string, string>
  styledName!: Record<string, string>
  previewImage?: GazelleRef<VixenAssets.Picture>
  className?: string

  constructor(attrs: ICatalogTag & WithRef<CatalogTag>) {
    super(attrs)
  }
}

export interface CreatorSchema
  extends CatalogPageSchema,
    CatalogCommonSchema {}

export interface ICreator extends ICatalogPage, ICatalogCommon {}

type CreatorSchemaType = z.Schema<Creator, z.ZodTypeDef, CreatorSchema>

export const CreatorSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      'external-id': z.string().nullish(),
      'catalog-tags': z.array(GazelleRefSchema),
      dates: z.record(z.string()),
      content: z
        .array(OcelotContent.SlottedContent.ContentSlotSchema)
        .default([]),
      name: z.record(z.string()),
      'styled-name': z.record(z.string()),
      'preview-image': GazelleRefSchema.nullish(),
      'class-name': z.string().nullish(),
    }),
  )
  .transform(value => new Creator(recase(value))) satisfies CreatorSchemaType

export class Creator extends Entity<CreatorSchema> implements ICreator {
  static readonly typename = 'creator'
  static readonly schema: CreatorSchemaType = CreatorSchema
  static readonly parse = CreatorSchema.parse
  static readonly fields = [
    'ref',
    'external-id',
    'catalog-tags',
    'dates',
    'content',
    'name',
    'styled-name',
    'preview-image',
    'class-name',
  ]

  readonly typename = 'creator'

  ref!: GazelleRef<Creator>
  externalId?: string
  catalogTags!: Array<GazelleRef<CatalogTag>>
  dates!: Record<string, string>
  content!: Array<OcelotContent.SlottedContent.ContentSlot>
  name!: Record<string, string>
  styledName!: Record<string, string>
  previewImage?: GazelleRef<VixenAssets.Picture>
  className?: string

  constructor(attrs: ICreator & WithRef<Creator>) {
    super(attrs)
  }
}

export interface CatalogObjectSchema
  extends CatalogPageSchema,
    CatalogCommonSchema,
    CatalogPositionableSchema,
    VixenCore.VisitableSchema {
  creators: Array<CatalogObject.CreatorWithRoleSchema>
  media: Record<string, string>
  player?: GazelleRefSchema | null
  'description-audio'?: GazelleRefSchema | null
}

export interface ICatalogObject
  extends ICatalogPage,
    ICatalogCommon,
    ICatalogPositionable,
    VixenCore.IVisitable {
  creators: Array<CatalogObject.CreatorWithRole>
  media: Record<string, string>
  player?: GazelleRef<OcelotHibiki.IHibikiPlayer>
  descriptionAudio?: GazelleRef<VixenAssets.Audio>
}

type CatalogObjectSchemaType = z.Schema<
  CatalogObject,
  z.ZodTypeDef,
  CatalogObjectSchema
>

export const CatalogObjectSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      position: VixenSpatial.PositionSchema.nullish(),
      'wall-id': z.string().nullish(),
      space: VixenSpatial.FeatureRefSchema.nullish(),
      'vantage-spaces': z.array(VixenSpatial.FeatureRefSchema),
      feature: VixenSpatial.FeatureRefSchema.nullish(),
      venue: VixenSpatial.VenueRefSchema.nullish(),
      'external-id': z.string().nullish(),
      'catalog-tags': z.array(GazelleRefSchema),
      dates: z.record(z.string()),
      content: z
        .array(OcelotContent.SlottedContent.ContentSlotSchema)
        .default([]),
      name: z.record(z.string()),
      'styled-name': z.record(z.string()),
      'preview-image': GazelleRefSchema.nullish(),
      'class-name': z.string().nullish(),
      creators: z.array(CatalogObject.CreatorWithRoleSchema),
      media: z.record(z.string()),
      player: GazelleRefSchema.nullish(),
      'description-audio': GazelleRefSchema.nullish(),
    }),
  )
  .transform(
    value => new CatalogObject(recase(value)),
  ) satisfies CatalogObjectSchemaType

export class CatalogObject
  extends Entity<CatalogObjectSchema>
  implements ICatalogObject
{
  static readonly typename = 'catalog-object'
  static readonly schema: CatalogObjectSchemaType = CatalogObjectSchema
  static readonly parse = CatalogObjectSchema.parse
  static readonly fields = [
    'ref',
    'position',
    'wall-id',
    'space',
    'vantage-spaces',
    'feature',
    'venue',
    'external-id',
    'catalog-tags',
    'dates',
    'content',
    'name',
    'styled-name',
    'preview-image',
    'class-name',
    'creators',
    'media',
    'player',
    'description-audio',
  ]

  readonly typename = 'catalog-object'

  ref!: GazelleRef<CatalogObject>
  position?: VixenSpatial.Position
  wallId?: string
  space?: VixenSpatial.FeatureRef
  vantageSpaces!: Array<VixenSpatial.FeatureRef>
  feature?: VixenSpatial.FeatureRef
  venue?: VixenSpatial.VenueRef
  externalId?: string
  catalogTags!: Array<GazelleRef<CatalogTag>>
  dates!: Record<string, string>
  content!: Array<OcelotContent.SlottedContent.ContentSlot>
  name!: Record<string, string>
  styledName!: Record<string, string>
  previewImage?: GazelleRef<VixenAssets.Picture>
  className?: string
  creators!: Array<CatalogObject.CreatorWithRole>
  media!: Record<string, string>
  player?: GazelleRef<OcelotHibiki.IHibikiPlayer>
  descriptionAudio?: GazelleRef<VixenAssets.Audio>

  constructor(attrs: ICatalogObject & WithRef<CatalogObject>) {
    super(attrs)
  }
}

export namespace CatalogObject {
  export interface CreatorWithRoleSchema {
    creator: GazelleRefSchema
    role: Record<string, string>
  }

  export interface ICreatorWithRole {
    creator: GazelleRef<Creator>
    role: Record<string, string>
  }

  type CreatorWithRoleSchemaType = z.Schema<
    CreatorWithRole,
    z.ZodTypeDef,
    CreatorWithRoleSchema
  >

  export const CreatorWithRoleSchema = z
    .lazy(() =>
      z.object({
        creator: GazelleRefSchema,
        role: z.record(z.string()),
      }),
    )
    .transform(
      value => new CreatorWithRole(recase(value)),
    ) satisfies CreatorWithRoleSchemaType

  export class CreatorWithRole
    extends Struct<CreatorWithRoleSchema>
    implements ICreatorWithRole
  {
    static readonly typename = 'creator-with-role'
    static readonly schema: CreatorWithRoleSchemaType = CreatorWithRoleSchema
    static readonly parse = CreatorWithRoleSchema.parse
    static readonly fields = ['creator', 'role']

    readonly typename = 'creator-with-role'

    creator!: GazelleRef<Creator>
    role!: Record<string, string>

    constructor(attrs: ICreatorWithRole) {
      super(attrs)
    }
  }
}

export interface CatalogGroupSchema
  extends CatalogPageSchema,
    CatalogPositionableSchema {
  objects: Array<GazelleRefSchema>
  'correlation-id'?: string | null
}

export interface ICatalogGroup extends ICatalogPage, ICatalogPositionable {
  objects: Array<GazelleRef<CatalogObject>>
  correlationId?: string
}

type CatalogGroupSchemaType = z.Schema<
  CatalogGroup,
  z.ZodTypeDef,
  CatalogGroupSchema
>

export const CatalogGroupSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      position: VixenSpatial.PositionSchema.nullish(),
      'wall-id': z.string().nullish(),
      space: VixenSpatial.FeatureRefSchema.nullish(),
      'vantage-spaces': z.array(VixenSpatial.FeatureRefSchema),
      feature: VixenSpatial.FeatureRefSchema.nullish(),
      venue: VixenSpatial.VenueRefSchema.nullish(),
      content: z
        .array(OcelotContent.SlottedContent.ContentSlotSchema)
        .default([]),
      name: z.record(z.string()),
      'styled-name': z.record(z.string()),
      'preview-image': GazelleRefSchema.nullish(),
      'class-name': z.string().nullish(),
      objects: z.array(GazelleRefSchema),
      'correlation-id': z.string().nullish(),
    }),
  )
  .transform(
    value => new CatalogGroup(recase(value)),
  ) satisfies CatalogGroupSchemaType

export class CatalogGroup
  extends Entity<CatalogGroupSchema>
  implements ICatalogGroup
{
  static readonly typename = 'catalog-group'
  static readonly schema: CatalogGroupSchemaType = CatalogGroupSchema
  static readonly parse = CatalogGroupSchema.parse
  static readonly fields = [
    'ref',
    'position',
    'wall-id',
    'space',
    'vantage-spaces',
    'feature',
    'venue',
    'content',
    'name',
    'styled-name',
    'preview-image',
    'class-name',
    'objects',
    'correlation-id',
  ]

  readonly typename = 'catalog-group'

  ref!: GazelleRef<CatalogGroup>
  position?: VixenSpatial.Position
  wallId?: string
  space?: VixenSpatial.FeatureRef
  vantageSpaces!: Array<VixenSpatial.FeatureRef>
  feature?: VixenSpatial.FeatureRef
  venue?: VixenSpatial.VenueRef
  content!: Array<OcelotContent.SlottedContent.ContentSlot>
  name!: Record<string, string>
  styledName!: Record<string, string>
  previewImage?: GazelleRef<VixenAssets.Picture>
  className?: string
  objects!: Array<GazelleRef<CatalogObject>>
  correlationId?: string

  constructor(attrs: ICatalogGroup & WithRef<CatalogGroup>) {
    super(attrs)
  }
}

export type INTERFACES = VixenCore.INTERFACES &
  VixenAssets.INTERFACES &
  VixenSpatial.INTERFACES &
  OcelotContent.INTERFACES &
  OcelotHibiki.INTERFACES & {
    'catalog-page': ICatalogPage
    'catalog-common': ICatalogCommon
    'catalog-positionable': ICatalogPositionable
  }

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  ...VixenAssets.ENTITIES,
  ...VixenSpatial.ENTITIES,
  ...OcelotContent.ENTITIES,
  ...OcelotHibiki.ENTITIES,
  'catalog-tag': CatalogTag,
  creator: Creator,
  'catalog-object': CatalogObject,
  'catalog-group': CatalogGroup,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '0.14.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
