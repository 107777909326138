/** **************************************************************************************
 * Cache control
 * ************************************************************************************** */

import { CacheableResponsePlugin } from 'workbox-cacheable-response'
import { ExpirationPlugin } from 'workbox-expiration'
import {
  cleanupOutdatedCaches,
  createHandlerBoundToURL,
  precacheAndRoute,
} from 'workbox-precaching'
import { RangeRequestsPlugin } from 'workbox-range-requests'
import { NavigationRoute, registerRoute } from 'workbox-routing'
import {
  CacheFirst,
  NetworkFirst,
  NetworkOnly,
  StaleWhileRevalidate,
} from 'workbox-strategies'

import { debugLog } from '../utils/debug'
import getEnv from '../utils/getEnv'

/**
 * Register routes that don't depend on the environment.
 */
export function preRegisterRoutes(): void {
  // Clean up old caches
  cleanupOutdatedCaches()

  // Precache all of the assets generated by your build process.
  // Their URLs are injected into the manifest variable below.
  // This variable must be present somewhere in your service worker file,
  // even if you decide not to use precaching. See https://cra.link/PWA
  precacheAndRoute(self.__WB_MANIFEST)

  // Redirect all URLs not in `denylist` to return `index.html`, this allows
  // our app to work as an SPA, where arbitrary routes (i.e. /building) will
  // start from `index.html`.
  // URLs like assets should be included in the `denylist` so they'll still be
  // served directly.
  registerRoute(
    new NavigationRoute(createHandlerBoundToURL('/index.html'), {
      denylist: [
        // If this looks like a URL for a resource, because it contains a file extension
        new RegExp('/[^/?]+\\.[^/]+$'),
        // If this is a URL that starts with /_
        new RegExp('/^/_/'),
        // If this is a URL that starts with /.well-known
        new RegExp('/.well-known/'),
      ],
    }),
  )

  // --- Fonts ---

  // Cache the Google Fonts stylesheets with a stale-while-revalidate strategy.
  // @see https://developers.google.com/web/tools/workbox/guides/common-recipes#google_fonts
  registerRoute(
    ({ url }) => url.origin === 'https://fonts.googleapis.com',
    new StaleWhileRevalidate({
      cacheName: 'google-fonts-stylesheets',
    }),
  )

  // Cache the underlying font files with a cache-first strategy for 1 year.
  // @see https://developers.google.com/web/tools/workbox/guides/common-recipes#google_fonts
  registerRoute(
    ({ url }) => url.origin === 'https://fonts.gstatic.com',
    new CacheFirst({
      cacheName: 'google-fonts-webfonts',
      plugins: [
        new CacheableResponsePlugin({
          statuses: [0, 200],
        }),
        new ExpirationPlugin({
          maxAgeSeconds: 60 * 60 * 24 * 365,
          maxEntries: 30,
        }),
      ],
    }),
  )
}

/**
 * Initialise cache control, and how URLs are handled by the service worker for
 * caching.
 *
 * This should only be called once the dynamic env block is copied across to
 * the service worker, i.e. via the INITIALIZE event.
 */
export function postRegisterRoutes(): void {
  const { VITE_APP_CONTENT_DOMAIN, disableCache } = getEnv()

  const DOMAIN = VITE_APP_CONTENT_DOMAIN.replace(/\//g, '\\/')

  if (disableCache) {
    debugLog('Service Worker', 'caching disabled by environment')
  } else {
    debugLog('Service Worker', 'registering routes')
  }

  /**
   * If caching is enabled, do {@link CacheFirst}
   *
   * This should only be used on assets that will be intercepted via Spidercache.
   */
  const MaybeCacheFirst = disableCache ? NetworkOnly : CacheFirst
  /**
   * If caching is enabled, do {@link NetworkFirst}
   *
   * This should only be used on assets that will be intercepted via Spidercache.
   */
  const MaybeNetworkFirst = disableCache ? NetworkOnly : NetworkFirst

  // --- Content Bundles ---

  // Get the latest Pladia manifest file entrypoint from the network first
  // It's the only file in the published set that's not immutable
  // We only bother to cache public files
  const LATEST_CONTENT_BUNDLE_REGEX = new RegExp(
    // eslint-disable-next-line no-useless-escape
    `${DOMAIN}\/bundles\/(public|preview)\/latest\/[^\/\.]+.json`,
    'i',
  )

  registerRoute(
    ({ url }) => {
      return (
        url.origin === VITE_APP_CONTENT_DOMAIN &&
        LATEST_CONTENT_BUNDLE_REGEX.test(url.href)
      )
    },
    new MaybeNetworkFirst({
      cacheName: 'content-latest',
    }),
  )

  // Bundle files other than latest/ are static
  // We only bother to cache public files
  const CONTENT_BUNDLE_REGEX = new RegExp(
    // eslint-disable-next-line no-useless-escape
    `${DOMAIN}\/bundles\/(public|preview)\/[^\/]+\/`,
    'i',
  )

  registerRoute(
    ({ url }) => {
      return (
        url.origin === VITE_APP_CONTENT_DOMAIN &&
        CONTENT_BUNDLE_REGEX.test(url.href) &&
        !LATEST_CONTENT_BUNDLE_REGEX.test(url.href)
      )
    },
    new MaybeCacheFirst({
      cacheName: 'content',
      plugins: [
        new ExpirationPlugin({
          maxAgeSeconds: 60 * 60 * 24 * 365,
          // There are a minimum of 3 files, or more if there are multiple venues
          maxEntries: 30,
        }),
      ],
    }),
  )

  // --- Assets ---

  // We use CacheFirst for assets because assets are immutable.
  //
  // See https://developers.google.com/web/tools/workbox/guides/common-recipes#caching_images
  registerRoute(
    ({ request }) => request.destination === 'image',
    new MaybeCacheFirst({
      cacheName: 'image-cache',
      plugins: [
        new CacheableResponsePlugin({
          statuses: [0, 200],
        }),
        new ExpirationPlugin({
          // This is an estimation of a number of objects * breakpoints,
          // just to keep the cache a meaningful size
          maxEntries: 10000,
        }),
      ],
    }),
  )

  // We use CacheFirst for assets because assets are immutable.
  //
  // See https://developer.chrome.com/docs/workbox/serving-cached-audio-and-video
  registerRoute(
    ({ request }) =>
      request.destination === 'audio' || request.destination === 'video',
    new MaybeCacheFirst({
      cacheName: 'media-cache',
      plugins: [
        new CacheableResponsePlugin({
          statuses: [0, 200],
        }),
        new ExpirationPlugin({
          maxEntries: 100,
        }),
        new RangeRequestsPlugin(),
      ],
    }),
  )
}
