import { memo as memoize } from 'radash'

import type { PageOptions, TrackOptions } from './rudderstack'

export const debugLog = (label: string, ...values: any[]): void => {
  console.log(`%c${label}`, 'color: goldenrod;', ...values)
}

export const debugAnalytics = (
  options: PageOptions | TrackOptions,
  ...values: any[]
): void => {
  const { label, name, color } =
    'name' in options
      ? {
          label: 'page',
          name: options.name,
          color: 'mediumvioletred',
        }
      : {
          label: 'track',
          name: options.event,
          color: 'orchid',
        }

  console.log(
    `%cRudderstack: %c${label} %c${name}`,
    `color: ${color};`,
    `color: ${color}; font-weight: bold;`,
    'text-decoration: underline;',
    options,
    ...values,
  )
}

export const debugError = (label: string, ...values: any[]): void => {
  console.log(`%cError: ${label}`, 'color: red; font-weight: bold;', ...values)
}

/**
 * This doesn't allow a debug error to be run more than once.
 */
export const memoizedDebugError = memoize(debugError)

export const debugPerformance = (
  label: string,
  t1: number,
  t0: number,
): any => {
  if (import.meta.env.DEV) {
    console.info(
      `%cPerformance: %c${label}:`,
      'color: darkorange;',
      'color: orange;',
      `${t1 - t0} milliseconds.`,
    )
  }
}
