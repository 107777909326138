/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'
import * as VixenAssets from './vixen-assets'

export const meta: Meta = {
  name: 'Ocelot Content',
  moduleName: 'ocelot-content',
  version: '0.15.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- content-block ---
export interface ContentBlockSchema
  extends VixenCore.StyledNamedSchema,
    VixenCore.RoutableSchema {
  'class-name'?: string | null
}

export interface IContentBlock
  extends VixenCore.IStyledNamed,
    VixenCore.IRoutable {
  className?: string
}

// --- slotted-content ---
export interface SlottedContentSchema {
  content?: Array<SlottedContent.ContentSlotSchema>
}

export interface ISlottedContent {
  content: Array<SlottedContent.ContentSlot>
}

export namespace SlottedContent {
  export interface ContentSlotSchema {
    slot: string
    content: GazelleRefSchema
  }

  export interface IContentSlot {
    slot: string
    content: GazelleRef<VixenCore.IContent>
  }

  type ContentSlotSchemaType = z.Schema<
    ContentSlot,
    z.ZodTypeDef,
    ContentSlotSchema
  >

  export const ContentSlotSchema = z
    .lazy(() =>
      z.object({
        slot: z.string(),
        content: GazelleRefSchema,
      }),
    )
    .transform(
      value => new ContentSlot(recase(value)),
    ) satisfies ContentSlotSchemaType

  export class ContentSlot
    extends Struct<ContentSlotSchema>
    implements IContentSlot
  {
    static readonly typename = 'content-slot'
    static readonly schema: ContentSlotSchemaType = ContentSlotSchema
    static readonly parse = ContentSlotSchema.parse
    static readonly fields = ['slot', 'content']

    readonly typename = 'content-slot'

    slot!: string
    content!: GazelleRef<VixenCore.IContent>

    constructor(attrs: IContentSlot) {
      super(attrs)
    }
  }
}

// === structs ===
// === entities ===
export interface InternalContentPageSchema
  extends VixenCore.NamedSchema,
    VixenCore.RoutableSchema {
  content: GazelleRefSchema
}

export interface IInternalContentPage
  extends VixenCore.INamed,
    VixenCore.IRoutable {
  content: GazelleRef<VixenCore.IContent>
}

type InternalContentPageSchemaType = z.Schema<
  InternalContentPage,
  z.ZodTypeDef,
  InternalContentPageSchema
>

export const InternalContentPageSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      content: GazelleRefSchema,
    }),
  )
  .transform(
    value => new InternalContentPage(recase(value)),
  ) satisfies InternalContentPageSchemaType

export class InternalContentPage
  extends Entity<InternalContentPageSchema>
  implements IInternalContentPage
{
  static readonly typename = 'internal-content-page'
  static readonly schema: InternalContentPageSchemaType =
    InternalContentPageSchema
  static readonly parse = InternalContentPageSchema.parse
  static readonly fields = ['ref', 'name', 'content']

  readonly typename = 'internal-content-page'

  ref!: GazelleRef<InternalContentPage>
  name!: Record<string, string>
  content!: GazelleRef<VixenCore.IContent>

  constructor(attrs: IInternalContentPage & WithRef<InternalContentPage>) {
    super(attrs)
  }
}

export interface ExternalContentPageSchema extends VixenCore.RoutableSchema {
  url: string
}

export interface IExternalContentPage extends VixenCore.IRoutable {
  url: string
}

type ExternalContentPageSchemaType = z.Schema<
  ExternalContentPage,
  z.ZodTypeDef,
  ExternalContentPageSchema
>

export const ExternalContentPageSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      url: z.string(),
    }),
  )
  .transform(
    value => new ExternalContentPage(recase(value)),
  ) satisfies ExternalContentPageSchemaType

export class ExternalContentPage
  extends Entity<ExternalContentPageSchema>
  implements IExternalContentPage
{
  static readonly typename = 'external-content-page'
  static readonly schema: ExternalContentPageSchemaType =
    ExternalContentPageSchema
  static readonly parse = ExternalContentPageSchema.parse
  static readonly fields = ['ref', 'url']

  readonly typename = 'external-content-page'

  ref!: GazelleRef<ExternalContentPage>
  url!: string

  constructor(attrs: IExternalContentPage & WithRef<ExternalContentPage>) {
    super(attrs)
  }
}

export interface ContentStackSchema extends VixenCore.ContentSchema {
  blocks: Array<GazelleRefSchema>
}

export interface IContentStack extends VixenCore.IContent {
  blocks: Array<GazelleRef<IContentBlock>>
}

type ContentStackSchemaType = z.Schema<
  ContentStack,
  z.ZodTypeDef,
  ContentStackSchema
>

export const ContentStackSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      blocks: z.array(GazelleRefSchema),
    }),
  )
  .transform(
    value => new ContentStack(recase(value)),
  ) satisfies ContentStackSchemaType

export class ContentStack
  extends Entity<ContentStackSchema>
  implements IContentStack
{
  static readonly typename = 'content-stack'
  static readonly schema: ContentStackSchemaType = ContentStackSchema
  static readonly parse = ContentStackSchema.parse
  static readonly fields = ['ref', 'blocks']

  readonly typename = 'content-stack'

  ref!: GazelleRef<ContentStack>
  blocks!: Array<GazelleRef<IContentBlock>>

  constructor(attrs: IContentStack & WithRef<ContentStack>) {
    super(attrs)
  }
}

export interface HtmlBlockSchema
  extends VixenCore.ContentSchema,
    ContentBlockSchema {
  html: Record<string, string>
}

export interface IHtmlBlock extends VixenCore.IContent, IContentBlock {
  html: Record<string, string>
}

type HtmlBlockSchemaType = z.Schema<HtmlBlock, z.ZodTypeDef, HtmlBlockSchema>

export const HtmlBlockSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      'styled-name': z.record(z.string()),
      'class-name': z.string().nullish(),
      html: z.record(z.string()),
    }),
  )
  .transform(
    value => new HtmlBlock(recase(value)),
  ) satisfies HtmlBlockSchemaType

export class HtmlBlock extends Entity<HtmlBlockSchema> implements IHtmlBlock {
  static readonly typename = 'html-block'
  static readonly schema: HtmlBlockSchemaType = HtmlBlockSchema
  static readonly parse = HtmlBlockSchema.parse
  static readonly fields = ['ref', 'name', 'styled-name', 'class-name', 'html']

  readonly typename = 'html-block'

  ref!: GazelleRef<HtmlBlock>
  name!: Record<string, string>
  styledName!: Record<string, string>
  className?: string
  html!: Record<string, string>

  constructor(attrs: IHtmlBlock & WithRef<HtmlBlock>) {
    super(attrs)
  }
}

export interface ExternalUrlCtaBlockSchema extends ContentBlockSchema {
  text: Record<string, string>
  url: string
  target: 'modal' | 'external'
}

export interface IExternalUrlCtaBlock extends IContentBlock {
  text: Record<string, string>
  url: string
  target: 'modal' | 'external'
}

type ExternalUrlCtaBlockSchemaType = z.Schema<
  ExternalUrlCtaBlock,
  z.ZodTypeDef,
  ExternalUrlCtaBlockSchema
>

export const ExternalUrlCtaBlockSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      'styled-name': z.record(z.string()),
      'class-name': z.string().nullish(),
      text: z.record(z.string()),
      url: z.string(),
      target: z.enum(['modal', 'external']),
    }),
  )
  .transform(
    value => new ExternalUrlCtaBlock(recase(value)),
  ) satisfies ExternalUrlCtaBlockSchemaType

export class ExternalUrlCtaBlock
  extends Entity<ExternalUrlCtaBlockSchema>
  implements IExternalUrlCtaBlock
{
  static readonly typename = 'external-url-cta-block'
  static readonly schema: ExternalUrlCtaBlockSchemaType =
    ExternalUrlCtaBlockSchema
  static readonly parse = ExternalUrlCtaBlockSchema.parse
  static readonly fields = [
    'ref',
    'name',
    'styled-name',
    'class-name',
    'text',
    'url',
    'target',
  ]

  readonly typename = 'external-url-cta-block'

  ref!: GazelleRef<ExternalUrlCtaBlock>
  name!: Record<string, string>
  styledName!: Record<string, string>
  className?: string
  text!: Record<string, string>
  url!: string
  target!: 'modal' | 'external'

  constructor(attrs: IExternalUrlCtaBlock & WithRef<ExternalUrlCtaBlock>) {
    super(attrs)
  }
}

export interface CarouselBlockSchema
  extends VixenCore.ContentSchema,
    ContentBlockSchema {
  pictures: Array<CarouselBlock.PictureSlotSchema>
}

export interface ICarouselBlock extends VixenCore.IContent, IContentBlock {
  pictures: Array<CarouselBlock.PictureSlot>
}

type CarouselBlockSchemaType = z.Schema<
  CarouselBlock,
  z.ZodTypeDef,
  CarouselBlockSchema
>

export const CarouselBlockSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      'styled-name': z.record(z.string()),
      'class-name': z.string().nullish(),
      pictures: z.array(CarouselBlock.PictureSlotSchema),
    }),
  )
  .transform(
    value => new CarouselBlock(recase(value)),
  ) satisfies CarouselBlockSchemaType

export class CarouselBlock
  extends Entity<CarouselBlockSchema>
  implements ICarouselBlock
{
  static readonly typename = 'carousel-block'
  static readonly schema: CarouselBlockSchemaType = CarouselBlockSchema
  static readonly parse = CarouselBlockSchema.parse
  static readonly fields = [
    'ref',
    'name',
    'styled-name',
    'class-name',
    'pictures',
  ]

  readonly typename = 'carousel-block'

  ref!: GazelleRef<CarouselBlock>
  name!: Record<string, string>
  styledName!: Record<string, string>
  className?: string
  pictures!: Array<CarouselBlock.PictureSlot>

  constructor(attrs: ICarouselBlock & WithRef<CarouselBlock>) {
    super(attrs)
  }
}

export namespace CarouselBlock {
  export interface PictureSlotSchema {
    picture: GazelleRefSchema
    caption: Record<string, string>
  }

  export interface IPictureSlot {
    picture: GazelleRef<VixenAssets.Picture>
    caption: Record<string, string>
  }

  type PictureSlotSchemaType = z.Schema<
    PictureSlot,
    z.ZodTypeDef,
    PictureSlotSchema
  >

  export const PictureSlotSchema = z
    .lazy(() =>
      z.object({
        picture: GazelleRefSchema,
        caption: z.record(z.string()),
      }),
    )
    .transform(
      value => new PictureSlot(recase(value)),
    ) satisfies PictureSlotSchemaType

  export class PictureSlot
    extends Struct<PictureSlotSchema>
    implements IPictureSlot
  {
    static readonly typename = 'picture-slot'
    static readonly schema: PictureSlotSchemaType = PictureSlotSchema
    static readonly parse = PictureSlotSchema.parse
    static readonly fields = ['picture', 'caption']

    readonly typename = 'picture-slot'

    picture!: GazelleRef<VixenAssets.Picture>
    caption!: Record<string, string>

    constructor(attrs: IPictureSlot) {
      super(attrs)
    }
  }
}

export interface VimeoBlockSchema extends ContentBlockSchema {
  caption: Record<string, string>
  url: string
}

export interface IVimeoBlock extends IContentBlock {
  caption: Record<string, string>
  url: string
}

type VimeoBlockSchemaType = z.Schema<
  VimeoBlock,
  z.ZodTypeDef,
  VimeoBlockSchema
>

export const VimeoBlockSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      'styled-name': z.record(z.string()),
      'class-name': z.string().nullish(),
      caption: z.record(z.string()),
      url: z.string(),
    }),
  )
  .transform(
    value => new VimeoBlock(recase(value)),
  ) satisfies VimeoBlockSchemaType

export class VimeoBlock
  extends Entity<VimeoBlockSchema>
  implements IVimeoBlock
{
  static readonly typename = 'vimeo-block'
  static readonly schema: VimeoBlockSchemaType = VimeoBlockSchema
  static readonly parse = VimeoBlockSchema.parse
  static readonly fields = [
    'ref',
    'name',
    'styled-name',
    'class-name',
    'caption',
    'url',
  ]

  readonly typename = 'vimeo-block'

  ref!: GazelleRef<VimeoBlock>
  name!: Record<string, string>
  styledName!: Record<string, string>
  className?: string
  caption!: Record<string, string>
  url!: string

  constructor(attrs: IVimeoBlock & WithRef<VimeoBlock>) {
    super(attrs)
  }
}

export type INTERFACES = VixenCore.INTERFACES &
  VixenAssets.INTERFACES & {
    'content-block': IContentBlock
    'slotted-content': ISlottedContent
  }

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  ...VixenAssets.ENTITIES,
  'internal-content-page': InternalContentPage,
  'external-content-page': ExternalContentPage,
  'content-stack': ContentStack,
  'html-block': HtmlBlock,
  'external-url-cta-block': ExternalUrlCtaBlock,
  'carousel-block': CarouselBlock,
  'vimeo-block': VimeoBlock,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '0.15.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
