/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'

export const meta: Meta = {
  name: 'Vixen Rules',
  moduleName: 'vixen-rules',
  version: '1.0.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- trigger ---
export interface TriggerSchema {
  'leading-debounce': number
  'trailing-debounce': number
}

export interface ITrigger {
  leadingDebounce: number
  trailingDebounce: number
}

// === structs ===
// === entities ===
export type INTERFACES = VixenCore.INTERFACES & {
  trigger: ITrigger
}

export const ENTITIES = {
  ...VixenCore.ENTITIES,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '1.0.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
