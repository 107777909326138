/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'
import * as VixenRules from './vixen-rules'
import * as VixenAssets from './vixen-assets'

export const meta: Meta = {
  name: 'Vixen Spatial',
  moduleName: 'vixen-spatial',
  version: '0.11.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- positionable ---
export interface PositionableSchema {
  position?: PositionSchema | null
}

export interface IPositionable {
  position?: Position
}

// --- positioned ---
export interface PositionedSchema {
  position: PositionSchema
}

export interface IPositioned {
  position: Position
}

// === structs ===
export interface FeatureRefSchema {
  'feature-type': string
  uuid: string
}

export interface IFeatureRef {
  featureType: string
  uuid: string
}

type FeatureRefSchemaType = z.Schema<
  FeatureRef,
  z.ZodTypeDef,
  FeatureRefSchema
>

export const FeatureRefSchema = z
  .lazy(() =>
    z.object({
      'feature-type': z.string(),
      uuid: z.string().nonempty(),
    }),
  )
  .transform(
    value => new FeatureRef(recase(value)),
  ) satisfies FeatureRefSchemaType

export class FeatureRef
  extends Struct<FeatureRefSchema>
  implements IFeatureRef
{
  static readonly typename = 'feature-ref'
  static readonly schema: FeatureRefSchemaType = FeatureRefSchema
  static readonly parse = FeatureRefSchema.parse
  static readonly fields = ['feature-type', 'uuid']

  readonly typename = 'feature-ref'

  featureType!: string
  uuid!: string

  constructor(attrs: IFeatureRef) {
    super(attrs)
  }
}

export interface LevelRefSchema {
  'feature-type': 'level'
  uuid: string
}

export interface ILevelRef {
  featureType: 'level'
  uuid: string
}

type LevelRefSchemaType = z.Schema<LevelRef, z.ZodTypeDef, LevelRefSchema>

export const LevelRefSchema = z
  .lazy(() =>
    z.object({
      'feature-type': z.enum(['level']),
      uuid: z.string().nonempty(),
    }),
  )
  .transform(value => new LevelRef(recase(value))) satisfies LevelRefSchemaType

export class LevelRef extends Struct<LevelRefSchema> implements ILevelRef {
  static readonly typename = 'level-ref'
  static readonly schema: LevelRefSchemaType = LevelRefSchema
  static readonly parse = LevelRefSchema.parse
  static readonly fields = ['feature-type', 'uuid']

  readonly typename = 'level-ref'

  featureType!: 'level'
  uuid!: string

  constructor(attrs: ILevelRef) {
    super(attrs)
  }
}

export interface VenueRefSchema {
  'feature-type': 'venue'
  uuid: string
}

export interface IVenueRef {
  featureType: 'venue'
  uuid: string
}

type VenueRefSchemaType = z.Schema<VenueRef, z.ZodTypeDef, VenueRefSchema>

export const VenueRefSchema = z
  .lazy(() =>
    z.object({
      'feature-type': z.enum(['venue']),
      uuid: z.string().nonempty(),
    }),
  )
  .transform(value => new VenueRef(recase(value))) satisfies VenueRefSchemaType

export class VenueRef extends Struct<VenueRefSchema> implements IVenueRef {
  static readonly typename = 'venue-ref'
  static readonly schema: VenueRefSchemaType = VenueRefSchema
  static readonly parse = VenueRefSchema.parse
  static readonly fields = ['feature-type', 'uuid']

  readonly typename = 'venue-ref'

  featureType!: 'venue'
  uuid!: string

  constructor(attrs: IVenueRef) {
    super(attrs)
  }
}

export interface GeojsonGeometrySchema {
  type: 'Polygon' | 'MultiPolygon' | 'LineString' | 'MultiLineString' | 'Point'
  coordinates: any
}

export interface IGeojsonGeometry {
  type: 'Polygon' | 'MultiPolygon' | 'LineString' | 'MultiLineString' | 'Point'
  coordinates: any
}

type GeojsonGeometrySchemaType = z.Schema<
  GeojsonGeometry,
  z.ZodTypeDef,
  GeojsonGeometrySchema
>

export const GeojsonGeometrySchema = z
  .lazy(() =>
    z.object({
      type: z.enum([
        'Polygon',
        'MultiPolygon',
        'LineString',
        'MultiLineString',
        'Point',
      ]),
      coordinates: z.object({}).passthrough(),
    }),
  )
  .transform(
    value => new GeojsonGeometry(recase(value)),
  ) satisfies GeojsonGeometrySchemaType

export class GeojsonGeometry
  extends Struct<GeojsonGeometrySchema>
  implements IGeojsonGeometry
{
  static readonly typename = 'geojson-geometry'
  static readonly schema: GeojsonGeometrySchemaType = GeojsonGeometrySchema
  static readonly parse = GeojsonGeometrySchema.parse
  static readonly fields = ['type', 'coordinates']

  readonly typename = 'geojson-geometry'

  type!:
    | 'Polygon'
    | 'MultiPolygon'
    | 'LineString'
    | 'MultiLineString'
    | 'Point'
  coordinates!: any

  constructor(attrs: IGeojsonGeometry) {
    super(attrs)
  }
}

export interface GeojsonPolygonalSchema {
  type: 'Polygon' | 'MultiPolygon'
  coordinates: any
}

export interface IGeojsonPolygonal {
  type: 'Polygon' | 'MultiPolygon'
  coordinates: any
}

type GeojsonPolygonalSchemaType = z.Schema<
  GeojsonPolygonal,
  z.ZodTypeDef,
  GeojsonPolygonalSchema
>

export const GeojsonPolygonalSchema = z
  .lazy(() =>
    z.object({
      type: z.enum(['Polygon', 'MultiPolygon']),
      coordinates: z.object({}).passthrough(),
    }),
  )
  .transform(
    value => new GeojsonPolygonal(recase(value)),
  ) satisfies GeojsonPolygonalSchemaType

export class GeojsonPolygonal
  extends Struct<GeojsonPolygonalSchema>
  implements IGeojsonPolygonal
{
  static readonly typename = 'geojson-polygonal'
  static readonly schema: GeojsonPolygonalSchemaType = GeojsonPolygonalSchema
  static readonly parse = GeojsonPolygonalSchema.parse
  static readonly fields = ['type', 'coordinates']

  readonly typename = 'geojson-polygonal'

  type!: 'Polygon' | 'MultiPolygon'
  coordinates!: any

  constructor(attrs: IGeojsonPolygonal) {
    super(attrs)
  }
}

export interface GeojsonLinealSchema {
  type: 'LineString' | 'MultiLineString'
  coordinates: any
}

export interface IGeojsonLineal {
  type: 'LineString' | 'MultiLineString'
  coordinates: any
}

type GeojsonLinealSchemaType = z.Schema<
  GeojsonLineal,
  z.ZodTypeDef,
  GeojsonLinealSchema
>

export const GeojsonLinealSchema = z
  .lazy(() =>
    z.object({
      type: z.enum(['LineString', 'MultiLineString']),
      coordinates: z.object({}).passthrough(),
    }),
  )
  .transform(
    value => new GeojsonLineal(recase(value)),
  ) satisfies GeojsonLinealSchemaType

export class GeojsonLineal
  extends Struct<GeojsonLinealSchema>
  implements IGeojsonLineal
{
  static readonly typename = 'geojson-lineal'
  static readonly schema: GeojsonLinealSchemaType = GeojsonLinealSchema
  static readonly parse = GeojsonLinealSchema.parse
  static readonly fields = ['type', 'coordinates']

  readonly typename = 'geojson-lineal'

  type!: 'LineString' | 'MultiLineString'
  coordinates!: any

  constructor(attrs: IGeojsonLineal) {
    super(attrs)
  }
}

export interface GeojsonPointSchema {
  type: 'Point'
  coordinates: Array<number>
}

export interface IGeojsonPoint {
  type: 'Point'
  coordinates: Array<number>
}

type GeojsonPointSchemaType = z.Schema<
  GeojsonPoint,
  z.ZodTypeDef,
  GeojsonPointSchema
>

export const GeojsonPointSchema = z
  .lazy(() =>
    z.object({
      type: z.enum(['Point']),
      coordinates: z.array(z.number()),
    }),
  )
  .transform(
    value => new GeojsonPoint(recase(value)),
  ) satisfies GeojsonPointSchemaType

export class GeojsonPoint
  extends Struct<GeojsonPointSchema>
  implements IGeojsonPoint
{
  static readonly typename = 'geojson-point'
  static readonly schema: GeojsonPointSchemaType = GeojsonPointSchema
  static readonly parse = GeojsonPointSchema.parse
  static readonly fields = ['type', 'coordinates']

  readonly typename = 'geojson-point'

  type!: 'Point'
  coordinates!: Array<number>

  constructor(attrs: IGeojsonPoint) {
    super(attrs)
  }
}

export interface PositionSchema {
  level: LevelRefSchema
  geometry: GeojsonPointSchema
}

export interface IPosition {
  level: LevelRef
  geometry: GeojsonPoint
}

type PositionSchemaType = z.Schema<Position, z.ZodTypeDef, PositionSchema>

export const PositionSchema = z
  .lazy(() =>
    z.object({
      level: LevelRefSchema,
      geometry: GeojsonPointSchema,
    }),
  )
  .transform(value => new Position(recase(value))) satisfies PositionSchemaType

export class Position extends Struct<PositionSchema> implements IPosition {
  static readonly typename = 'position'
  static readonly schema: PositionSchemaType = PositionSchema
  static readonly parse = PositionSchema.parse
  static readonly fields = ['level', 'geometry']

  readonly typename = 'position'

  level!: LevelRef
  geometry!: GeojsonPoint

  constructor(attrs: IPosition) {
    super(attrs)
  }
}

// === entities ===
export interface MapSetSchema extends VixenAssets.AssetGroupSchema {
  venue: VenueRefSchema
  assets: Array<GazelleRefSchema>
}

export interface IMapSet extends VixenAssets.IAssetGroup {
  venue: VenueRef
  assets: Array<GazelleRef<MapAsset>>
}

type MapSetSchemaType = z.Schema<MapSet, z.ZodTypeDef, MapSetSchema>

export const MapSetSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      assets: z.array(GazelleRefSchema),
      'external-id': z.string().nullish(),
      venue: VenueRefSchema,
    }),
  )
  .transform(value => new MapSet(recase(value))) satisfies MapSetSchemaType

export class MapSet extends Entity<MapSetSchema> implements IMapSet {
  static readonly typename = 'map-set'
  static readonly schema: MapSetSchemaType = MapSetSchema
  static readonly parse = MapSetSchema.parse
  static readonly fields = ['ref', 'name', 'assets', 'external-id', 'venue']

  readonly typename = 'map-set'

  ref!: GazelleRef<MapSet>
  name!: Record<string, string>
  assets!: Array<GazelleRef<MapAsset>>
  externalId?: string
  venue!: VenueRef

  constructor(attrs: IMapSet & WithRef<MapSet>) {
    super(attrs)
  }
}

export interface MapAssetSchema extends VixenAssets.SpatialAssetSchema {
  level: LevelRefSchema
  'top-left': GeojsonPointSchema
  'top-right': GeojsonPointSchema
  'bottom-right': GeojsonPointSchema
  'bottom-left': GeojsonPointSchema
}

export interface IMapAsset extends VixenAssets.ISpatialAsset {
  level: LevelRef
  topLeft: GeojsonPoint
  topRight: GeojsonPoint
  bottomRight: GeojsonPoint
  bottomLeft: GeojsonPoint
}

type MapAssetSchemaType = z.Schema<MapAsset, z.ZodTypeDef, MapAssetSchema>

export const MapAssetSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      url: z.string(),
      'content-type': z.string(),
      language: z.string().nullish(),
      copyright: z.string().nullish(),
      'distribution-control': z.enum(['unrestricted', 'onsite', 'admin-only']),
      'external-id': z.string().nullish(),
      width: z.number(),
      height: z.number(),
      level: LevelRefSchema,
      'top-left': GeojsonPointSchema,
      'top-right': GeojsonPointSchema,
      'bottom-right': GeojsonPointSchema,
      'bottom-left': GeojsonPointSchema,
    }),
  )
  .transform(value => new MapAsset(recase(value))) satisfies MapAssetSchemaType

export class MapAsset extends Entity<MapAssetSchema> implements IMapAsset {
  static readonly typename = 'map-asset'
  static readonly schema: MapAssetSchemaType = MapAssetSchema
  static readonly parse = MapAssetSchema.parse
  static readonly fields = [
    'ref',
    'url',
    'content-type',
    'language',
    'copyright',
    'distribution-control',
    'external-id',
    'width',
    'height',
    'level',
    'top-left',
    'top-right',
    'bottom-right',
    'bottom-left',
  ]

  readonly typename = 'map-asset'

  ref!: GazelleRef<MapAsset>
  url!: string
  contentType!: string
  language?: string
  copyright?: string
  distributionControl!: 'unrestricted' | 'onsite' | 'admin-only'
  externalId?: string
  width!: number
  height!: number
  level!: LevelRef
  topLeft!: GeojsonPoint
  topRight!: GeojsonPoint
  bottomRight!: GeojsonPoint
  bottomLeft!: GeojsonPoint

  constructor(attrs: IMapAsset & WithRef<MapAsset>) {
    super(attrs)
  }
}

export interface SpatialTriggerSchema extends VixenRules.TriggerSchema {
  space: FeatureRefSchema
}

export interface ISpatialTrigger extends VixenRules.ITrigger {
  space: FeatureRef
}

type SpatialTriggerSchemaType = z.Schema<
  SpatialTrigger,
  z.ZodTypeDef,
  SpatialTriggerSchema
>

export const SpatialTriggerSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      'leading-debounce': z.number(),
      'trailing-debounce': z.number(),
      space: FeatureRefSchema,
    }),
  )
  .transform(
    value => new SpatialTrigger(recase(value)),
  ) satisfies SpatialTriggerSchemaType

export class SpatialTrigger
  extends Entity<SpatialTriggerSchema>
  implements ISpatialTrigger
{
  static readonly typename = 'spatial-trigger'
  static readonly schema: SpatialTriggerSchemaType = SpatialTriggerSchema
  static readonly parse = SpatialTriggerSchema.parse
  static readonly fields = [
    'ref',
    'leading-debounce',
    'trailing-debounce',
    'space',
  ]

  readonly typename = 'spatial-trigger'

  ref!: GazelleRef<SpatialTrigger>
  leadingDebounce!: number
  trailingDebounce!: number
  space!: FeatureRef

  constructor(attrs: ISpatialTrigger & WithRef<SpatialTrigger>) {
    super(attrs)
  }
}

export type INTERFACES = VixenCore.INTERFACES &
  VixenRules.INTERFACES &
  VixenAssets.INTERFACES & {
    positionable: IPositionable
    positioned: IPositioned
  }

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  ...VixenRules.ENTITIES,
  ...VixenAssets.ENTITIES,
  'map-set': MapSet,
  'map-asset': MapAsset,
  'spatial-trigger': SpatialTrigger,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '0.11.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
