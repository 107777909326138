/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'
import * as VixenSpatial from './vixen-spatial'

export const meta: Meta = {
  name: 'Eileen Wayfinding',
  moduleName: 'eileen-wayfinding',
  version: '0.0.1',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// === structs ===
// === entities ===
export interface WayfindingNavtrackSchema extends VixenCore.NamedSchema {
  waypoints?: Array<WayfindingNavtrack.WaypointSchema>
}

export interface IWayfindingNavtrack extends VixenCore.INamed {
  waypoints: Array<WayfindingNavtrack.Waypoint>
}

type WayfindingNavtrackSchemaType = z.Schema<
  WayfindingNavtrack,
  z.ZodTypeDef,
  WayfindingNavtrackSchema
>

export const WayfindingNavtrackSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      waypoints: z.array(WayfindingNavtrack.WaypointSchema).default([]),
    }),
  )
  .transform(
    value => new WayfindingNavtrack(recase(value)),
  ) satisfies WayfindingNavtrackSchemaType

export class WayfindingNavtrack
  extends Entity<WayfindingNavtrackSchema>
  implements IWayfindingNavtrack
{
  static readonly typename = 'wayfinding-navtrack'
  static readonly schema: WayfindingNavtrackSchemaType =
    WayfindingNavtrackSchema
  static readonly parse = WayfindingNavtrackSchema.parse
  static readonly fields = ['ref', 'name', 'waypoints']

  readonly typename = 'wayfinding-navtrack'

  ref!: GazelleRef<WayfindingNavtrack>
  name!: Record<string, string>
  waypoints!: Array<WayfindingNavtrack.Waypoint>

  constructor(attrs: IWayfindingNavtrack & WithRef<WayfindingNavtrack>) {
    super(attrs)
  }
}

export namespace WayfindingNavtrack {
  export interface WaypointSchema {
    key: string
    position: WayfindingNavtrack.WaypointPositionSchema
    'level-transition'?: 'ramp' | 'stairs' | 'escalator' | 'elevator' | null
    outbound?: Array<string>
    accessibility: WayfindingNavtrack.WaypointAccessibilitySchema
  }

  export interface IWaypoint {
    key: string
    position: WayfindingNavtrack.WaypointPosition
    levelTransition?: 'ramp' | 'stairs' | 'escalator' | 'elevator'
    outbound: Array<string>
    accessibility: WayfindingNavtrack.WaypointAccessibility
  }

  type WaypointSchemaType = z.Schema<Waypoint, z.ZodTypeDef, WaypointSchema>

  export const WaypointSchema = z
    .lazy(() =>
      z.object({
        key: z.string(),
        position: WayfindingNavtrack.WaypointPositionSchema,
        'level-transition': z
          .enum(['ramp', 'stairs', 'escalator', 'elevator'])
          .nullish(),
        outbound: z.array(z.string()).default([]),
        accessibility: WayfindingNavtrack.WaypointAccessibilitySchema,
      }),
    )
    .transform(
      value => new Waypoint(recase(value)),
    ) satisfies WaypointSchemaType

  export class Waypoint extends Struct<WaypointSchema> implements IWaypoint {
    static readonly typename = 'waypoint'
    static readonly schema: WaypointSchemaType = WaypointSchema
    static readonly parse = WaypointSchema.parse
    static readonly fields = [
      'key',
      'position',
      'level-transition',
      'outbound',
      'accessibility',
    ]

    readonly typename = 'waypoint'

    key!: string
    position!: WayfindingNavtrack.WaypointPosition
    levelTransition?: 'ramp' | 'stairs' | 'escalator' | 'elevator'
    outbound!: Array<string>
    accessibility!: WayfindingNavtrack.WaypointAccessibility

    constructor(attrs: IWaypoint) {
      super(attrs)
    }
  }

  export interface WaypointPositionSchema {
    geometry: VixenSpatial.GeojsonPointSchema
    levels: Array<VixenSpatial.LevelRefSchema>
  }

  export interface IWaypointPosition {
    geometry: VixenSpatial.GeojsonPoint
    levels: Array<VixenSpatial.LevelRef>
  }

  type WaypointPositionSchemaType = z.Schema<
    WaypointPosition,
    z.ZodTypeDef,
    WaypointPositionSchema
  >

  export const WaypointPositionSchema = z
    .lazy(() =>
      z.object({
        geometry: VixenSpatial.GeojsonPointSchema,
        levels: z.array(VixenSpatial.LevelRefSchema),
      }),
    )
    .transform(
      value => new WaypointPosition(recase(value)),
    ) satisfies WaypointPositionSchemaType

  export class WaypointPosition
    extends Struct<WaypointPositionSchema>
    implements IWaypointPosition
  {
    static readonly typename = 'waypoint-position'
    static readonly schema: WaypointPositionSchemaType = WaypointPositionSchema
    static readonly parse = WaypointPositionSchema.parse
    static readonly fields = ['geometry', 'levels']

    readonly typename = 'waypoint-position'

    geometry!: VixenSpatial.GeojsonPoint
    levels!: Array<VixenSpatial.LevelRef>

    constructor(attrs: IWaypointPosition) {
      super(attrs)
    }
  }

  export interface WaypointAccessibilitySchema {
    wheelchair?: boolean
    'child-safe'?: boolean
  }

  export interface IWaypointAccessibility {
    wheelchair: boolean
    childSafe: boolean
  }

  type WaypointAccessibilitySchemaType = z.Schema<
    WaypointAccessibility,
    z.ZodTypeDef,
    WaypointAccessibilitySchema
  >

  export const WaypointAccessibilitySchema = z
    .lazy(() =>
      z.object({
        wheelchair: z.boolean().default(true),
        'child-safe': z.boolean().default(true),
      }),
    )
    .transform(
      value => new WaypointAccessibility(recase(value)),
    ) satisfies WaypointAccessibilitySchemaType

  export class WaypointAccessibility
    extends Struct<WaypointAccessibilitySchema>
    implements IWaypointAccessibility
  {
    static readonly typename = 'waypoint-accessibility'
    static readonly schema: WaypointAccessibilitySchemaType =
      WaypointAccessibilitySchema
    static readonly parse = WaypointAccessibilitySchema.parse
    static readonly fields = ['wheelchair', 'child-safe']

    readonly typename = 'waypoint-accessibility'

    wheelchair!: boolean
    childSafe!: boolean

    constructor(attrs: IWaypointAccessibility) {
      super(attrs)
    }
  }
}

export type INTERFACES = VixenCore.INTERFACES & VixenSpatial.INTERFACES & {}

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  ...VixenSpatial.ENTITIES,
  'wayfinding-navtrack': WayfindingNavtrack,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '0.0.1'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
