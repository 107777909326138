/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'
import * as VixenAssets from './vixen-assets'

export const meta: Meta = {
  name: 'Ocelot Hibiki',
  moduleName: 'ocelot-hibiki',
  version: '1.0.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- hibiki-player ---
export interface HibikiPlayerSchema {}

export interface IHibikiPlayer {}

// === structs ===
// === entities ===
export interface HibikiBackgroundAudioPlayerSchema extends HibikiPlayerSchema {
  background: GazelleRefSchema
  volume: number
  'fade-in': number
  'fade-out': number
  delay: number
}

export interface IHibikiBackgroundAudioPlayer extends IHibikiPlayer {
  background: GazelleRef<VixenAssets.Audio>
  volume: number
  fadeIn: number
  fadeOut: number
  delay: number
}

type HibikiBackgroundAudioPlayerSchemaType = z.Schema<
  HibikiBackgroundAudioPlayer,
  z.ZodTypeDef,
  HibikiBackgroundAudioPlayerSchema
>

export const HibikiBackgroundAudioPlayerSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      background: GazelleRefSchema,
      volume: z.number(),
      'fade-in': z.number(),
      'fade-out': z.number(),
      delay: z.number(),
    }),
  )
  .transform(
    value => new HibikiBackgroundAudioPlayer(recase(value)),
  ) satisfies HibikiBackgroundAudioPlayerSchemaType

export class HibikiBackgroundAudioPlayer
  extends Entity<HibikiBackgroundAudioPlayerSchema>
  implements IHibikiBackgroundAudioPlayer
{
  static readonly typename = 'hibiki-background-audio-player'
  static readonly schema: HibikiBackgroundAudioPlayerSchemaType =
    HibikiBackgroundAudioPlayerSchema
  static readonly parse = HibikiBackgroundAudioPlayerSchema.parse
  static readonly fields = [
    'ref',
    'background',
    'volume',
    'fade-in',
    'fade-out',
    'delay',
  ]

  readonly typename = 'hibiki-background-audio-player'

  ref!: GazelleRef<HibikiBackgroundAudioPlayer>
  background!: GazelleRef<VixenAssets.Audio>
  volume!: number
  fadeIn!: number
  fadeOut!: number
  delay!: number

  constructor(
    attrs: IHibikiBackgroundAudioPlayer & WithRef<HibikiBackgroundAudioPlayer>,
  ) {
    super(attrs)
  }
}

export interface HibikiForegroundAudioPlayerSchema extends HibikiPlayerSchema {
  foreground: GazelleRefSchema
  volume: number
  'background-ducking': number
  reactivation: HibikiForegroundAudioPlayer.Reactivation
  'max-activations'?: number | null
  'cooldown-interval': number
  interruptors: HibikiForegroundAudioPlayer.InterruptorsSchema
}

export interface IHibikiForegroundAudioPlayer extends IHibikiPlayer {
  foreground: GazelleRef<VixenAssets.Audio>
  volume: number
  backgroundDucking: number
  reactivation: HibikiForegroundAudioPlayer.Reactivation
  maxActivations?: number
  cooldownInterval: number
  interruptors: HibikiForegroundAudioPlayer.Interruptors
}

type HibikiForegroundAudioPlayerSchemaType = z.Schema<
  HibikiForegroundAudioPlayer,
  z.ZodTypeDef,
  HibikiForegroundAudioPlayerSchema
>

export const HibikiForegroundAudioPlayerSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      foreground: GazelleRefSchema,
      volume: z.number(),
      'background-ducking': z.number(),
      reactivation: z.nativeEnum(HibikiForegroundAudioPlayer.Reactivation),
      'max-activations': z.number().int().nullish(),
      'cooldown-interval': z.number(),
      interruptors: HibikiForegroundAudioPlayer.InterruptorsSchema,
    }),
  )
  .transform(
    value => new HibikiForegroundAudioPlayer(recase(value)),
  ) satisfies HibikiForegroundAudioPlayerSchemaType

export class HibikiForegroundAudioPlayer
  extends Entity<HibikiForegroundAudioPlayerSchema>
  implements IHibikiForegroundAudioPlayer
{
  static readonly typename = 'hibiki-foreground-audio-player'
  static readonly schema: HibikiForegroundAudioPlayerSchemaType =
    HibikiForegroundAudioPlayerSchema
  static readonly parse = HibikiForegroundAudioPlayerSchema.parse
  static readonly fields = [
    'ref',
    'foreground',
    'volume',
    'background-ducking',
    'reactivation',
    'max-activations',
    'cooldown-interval',
    'interruptors',
  ]

  readonly typename = 'hibiki-foreground-audio-player'

  ref!: GazelleRef<HibikiForegroundAudioPlayer>
  foreground!: GazelleRef<VixenAssets.Audio>
  volume!: number
  backgroundDucking!: number
  reactivation!: HibikiForegroundAudioPlayer.Reactivation
  maxActivations?: number
  cooldownInterval!: number
  interruptors!: HibikiForegroundAudioPlayer.Interruptors

  constructor(
    attrs: IHibikiForegroundAudioPlayer & WithRef<HibikiForegroundAudioPlayer>,
  ) {
    super(attrs)
  }
}

export namespace HibikiForegroundAudioPlayer {
  export interface InterruptorsSchema {
    'foreground-audio-triggered': boolean
    'scene-trigger-end': boolean
  }

  export interface IInterruptors {
    foregroundAudioTriggered: boolean
    sceneTriggerEnd: boolean
  }

  type InterruptorsSchemaType = z.Schema<
    Interruptors,
    z.ZodTypeDef,
    InterruptorsSchema
  >

  export const InterruptorsSchema = z
    .lazy(() =>
      z.object({
        'foreground-audio-triggered': z.boolean(),
        'scene-trigger-end': z.boolean(),
      }),
    )
    .transform(
      value => new Interruptors(recase(value)),
    ) satisfies InterruptorsSchemaType

  export class Interruptors
    extends Struct<InterruptorsSchema>
    implements IInterruptors
  {
    static readonly typename = 'interruptors'
    static readonly schema: InterruptorsSchemaType = InterruptorsSchema
    static readonly parse = InterruptorsSchema.parse
    static readonly fields = [
      'foreground-audio-triggered',
      'scene-trigger-end',
    ]

    readonly typename = 'interruptors'

    foregroundAudioTriggered!: boolean
    sceneTriggerEnd!: boolean

    constructor(attrs: IInterruptors) {
      super(attrs)
    }
  }

  export enum Reactivation {
    ENTERING_SCENE = 'entering-scene',
    AFTER_OBJECT = 'after-object',
    CONTINUOUS = 'continuous',
  }
}

export interface HibikiPairedAudioPlayerSchema extends HibikiPlayerSchema {
  foreground: GazelleRefSchema
  background?: GazelleRefSchema | null
  'background-ducking': number
}

export interface IHibikiPairedAudioPlayer extends IHibikiPlayer {
  foreground: GazelleRef<VixenAssets.Audio>
  background?: GazelleRef<VixenAssets.Audio>
  backgroundDucking: number
}

type HibikiPairedAudioPlayerSchemaType = z.Schema<
  HibikiPairedAudioPlayer,
  z.ZodTypeDef,
  HibikiPairedAudioPlayerSchema
>

export const HibikiPairedAudioPlayerSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      foreground: GazelleRefSchema,
      background: GazelleRefSchema.nullish(),
      'background-ducking': z.number(),
    }),
  )
  .transform(
    value => new HibikiPairedAudioPlayer(recase(value)),
  ) satisfies HibikiPairedAudioPlayerSchemaType

export class HibikiPairedAudioPlayer
  extends Entity<HibikiPairedAudioPlayerSchema>
  implements IHibikiPairedAudioPlayer
{
  static readonly typename = 'hibiki-paired-audio-player'
  static readonly schema: HibikiPairedAudioPlayerSchemaType =
    HibikiPairedAudioPlayerSchema
  static readonly parse = HibikiPairedAudioPlayerSchema.parse
  static readonly fields = [
    'ref',
    'foreground',
    'background',
    'background-ducking',
  ]

  readonly typename = 'hibiki-paired-audio-player'

  ref!: GazelleRef<HibikiPairedAudioPlayer>
  foreground!: GazelleRef<VixenAssets.Audio>
  background?: GazelleRef<VixenAssets.Audio>
  backgroundDucking!: number

  constructor(
    attrs: IHibikiPairedAudioPlayer & WithRef<HibikiPairedAudioPlayer>,
  ) {
    super(attrs)
  }
}

export interface HibikiUnpairedAudioPlayerSchema extends HibikiPlayerSchema {
  foreground?: GazelleRefSchema | null
  background?: GazelleRefSchema | null
  'background-ducking': number
}

export interface IHibikiUnpairedAudioPlayer extends IHibikiPlayer {
  foreground?: GazelleRef<VixenAssets.Audio>
  background?: GazelleRef<VixenAssets.Audio>
  backgroundDucking: number
}

type HibikiUnpairedAudioPlayerSchemaType = z.Schema<
  HibikiUnpairedAudioPlayer,
  z.ZodTypeDef,
  HibikiUnpairedAudioPlayerSchema
>

export const HibikiUnpairedAudioPlayerSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      foreground: GazelleRefSchema.nullish(),
      background: GazelleRefSchema.nullish(),
      'background-ducking': z.number(),
    }),
  )
  .transform(
    value => new HibikiUnpairedAudioPlayer(recase(value)),
  ) satisfies HibikiUnpairedAudioPlayerSchemaType

export class HibikiUnpairedAudioPlayer
  extends Entity<HibikiUnpairedAudioPlayerSchema>
  implements IHibikiUnpairedAudioPlayer
{
  static readonly typename = 'hibiki-unpaired-audio-player'
  static readonly schema: HibikiUnpairedAudioPlayerSchemaType =
    HibikiUnpairedAudioPlayerSchema
  static readonly parse = HibikiUnpairedAudioPlayerSchema.parse
  static readonly fields = [
    'ref',
    'foreground',
    'background',
    'background-ducking',
  ]

  readonly typename = 'hibiki-unpaired-audio-player'

  ref!: GazelleRef<HibikiUnpairedAudioPlayer>
  foreground?: GazelleRef<VixenAssets.Audio>
  background?: GazelleRef<VixenAssets.Audio>
  backgroundDucking!: number

  constructor(
    attrs: IHibikiUnpairedAudioPlayer & WithRef<HibikiUnpairedAudioPlayer>,
  ) {
    super(attrs)
  }
}

export type INTERFACES = VixenCore.INTERFACES &
  VixenAssets.INTERFACES & {
    'hibiki-player': IHibikiPlayer
  }

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  ...VixenAssets.ENTITIES,
  'hibiki-background-audio-player': HibikiBackgroundAudioPlayer,
  'hibiki-foreground-audio-player': HibikiForegroundAudioPlayer,
  'hibiki-paired-audio-player': HibikiPairedAudioPlayer,
  'hibiki-unpaired-audio-player': HibikiUnpairedAudioPlayer,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '1.0.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
